import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup } from "@angular/forms";
import { BaseComponent } from '@page-modules/base/base.component';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent extends BaseComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() title: string;
  @Input() type: string;
  @Input() autocomplete = 'on';
  @Input() max: number;
  @Input() min: number;
  @Input() maxlength: number;

  constructor() {
    super();
  }

  public get control(): FormControl {
    return this.parentForm.get(this.controlName) as FormControl;
  }
}
