<div [formGroup]="parentForm" class="form-group">
  <div class="container">
    <input
      [formControlName]="controlName"
      [id]="controlName"
      [name]="controlName"
      [disabled]="control.disabled"
      class="form-checkbox-input"
      type="checkbox" />
    <label [for]="controlName" class="label form-label">{{title}}</label>
  </div>

  <app-validator-message [field]="control"></app-validator-message>
</div>
