<header class="header">
  <div class="header-container">
    <app-navbar></app-navbar>
  </div>
</header>
<div class="wrapper">
  <section class="content">
    <app-main-nav class="main-nav"></app-main-nav>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </section>
</div>
