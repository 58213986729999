import { Injectable, Type } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export class EventBase {}

type EventCallback<T> = (message: T) => void;

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private _handler = new Subject<EventBase>();

  broadcast(event: EventBase) {
    this._handler.next(event);
  }

  subscribe(type: Type<any>, callback: EventCallback<any>): Subscription {
    const typeName = type.name;
    return this._handler.pipe(filter((message) => message.constructor.name === typeName)).subscribe((message) => callback(message));
  }
}
