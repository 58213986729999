import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ClosingButtonType, DialogRef } from '@interops/dialog/dialog.ref';
import { DIALOG_CONFIG } from '@interops/dialog/dialog.token';
import { DialogConfig, DialogConfigType } from '@interops/dialog/dialog.config';
import { dialogAppearanceAndHidingAnimation } from '@shared/animations/dialog.animation';
import { AnimationEvent } from '@angular/animations';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  animations: [dialogAppearanceAndHidingAnimation],
})
export class DialogComponent implements AfterViewInit {
  @ViewChild('contentContainer') contentContainer: ElementRef;
  public animationStateChanged = new EventEmitter<AnimationEvent>();

  constructor(private _dialogRef: DialogRef, private _renderer: Renderer2, @Inject(DIALOG_CONFIG) readonly config: DialogConfig) {}

  private _isVisible = true;

  get isVisible(): boolean {
    return this._isVisible;
  }

  public ngAfterViewInit(): void {
    switch (this.config.type) {
      case DialogConfigType.success:
        this._renderer.addClass(this.contentContainer.nativeElement, 'success');
        break;
      case DialogConfigType.error:
        this._renderer.addClass(this.contentContainer.nativeElement, 'error');
        break;
      case DialogConfigType.warning:
        this._renderer.addClass(this.contentContainer.nativeElement, 'warning');
        break;
      case DialogConfigType.info:
        this._renderer.addClass(this.contentContainer.nativeElement, 'info');
        break;
    }
  }

  public btnCancel_OnClick() {
    this._dialogRef.close(ClosingButtonType.cancel);
  }

  public btnConfirm_OnClick() {
    this._dialogRef.close(ClosingButtonType.confirm);
  }

  public onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  public onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  public startExitAnimation() {
    this._isVisible = false;
  }
}
