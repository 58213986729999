import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@page-modules/base/base.component';

@Component({
  selector: 'app-big-rounded-button',
  templateUrl: './big-rounded-button.component.html',
  styleUrls: ['./big-rounded-button.component.scss'],
})
export class BigRoundedButtonComponent extends BaseComponent {
  @Input() imageName: string;
  @Input() imageHeight: number;
  @Input() imageWidth: number;
  @Input() title: string;
  @Input() isPrimary: boolean;

  @Output() clickBtn = new EventEmitter();

  constructor() {
    super();
  }

  btnClick() {
    this.clickBtn.emit();
  }
}
