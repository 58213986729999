import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { AuthGuard } from '@core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'main',
        loadChildren: () => import('./page-modules/main/main.module').then((m) => m.MainModule),
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./page-modules/auth/auth.module').then((m) => m.AuthModule),
  },
  // {
  //   path: 'game-constructor',
  //   component: ContentLayoutComponent,
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./page-modules/game-constructor/game-constructor.module').then(m => m.GameConstructorModule)
  // },
  // Fallback when no prior routes is matched
  { path: '**', redirectTo: '/auth/main', pathMatch: 'full' },
];
export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always'
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
