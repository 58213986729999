<div [formGroup]="parentForm" class="form-group">
  <label [for]="controlName" class="form-label">{{title}}</label>
  <textarea
    [formControlName]="controlName"
    [id]="controlName"
    [name]="controlName"
    [placeholder]="placeholder"
    [style.min-height.px]="minHeight"
    [maxlength]="maxlength"
    class="form-textarea-input">
  </textarea>
  <app-validator-message [field]="control"></app-validator-message>
</div>
