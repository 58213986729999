<div class="container">
  <div class="content">
    <div class="logo-container">
      <img class="logo-container__image" src="assets/images/logo_icon.png" />
      <p class="logo-container__title header header-small">{{'Navbar.Title' | translate}}</p>
    </div>
    <div class="user-container">
      <app-button (click)="btnSignOut_OnClick()" class="user-container__btn-sign-out"> {{'Navbar.SignOut' | translate}} </app-button>
      <app-avatar [height]="56" [width]="56" class="user-container__avatar"></app-avatar>
    </div>
  </div>
</div>
