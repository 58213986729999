import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Output() pageChanged = new EventEmitter<number>();
  public isBtnPrevDisabled: boolean;
  public isBtnNextDisabled: boolean;
  public pagesToLeftOfCurrentPage = new Array<number>();
  public pagesToLeftOfCurrentPageRemainder = new Array<number>();
  public pagesToRightOfCurrentPage = new Array<number>();
  public pagesToRightOfCurrentPageRemainder = new Array<number>();

  private _pageCount: number;
  @Input() public set pageCount(value: number) {
    if (this._pageCount === value) {
      return;
    }
    this._pageCount = value;
    this.updateLayout();
  }

  private _currentPage: number;

  public get currentPage(): number {
    return this._currentPage;
  }

  @Input() public set currentPage(value: number) {
    if (this._currentPage === value) {
      return;
    }
    this._currentPage = value;
    this.onCurrentPageChanged();
  }

  public ngOnInit(): void {
    this.currentPage = 1;
    this.pageCount = 1;
  }

  public btnPage_OnClick(pageNumber: number) {
    this.currentPage = pageNumber;
  }

  public btnPrev_OnClick() {
    if (!this.isBtnPrevDisabled) {
      this.currentPage -= 1;
    }
  }

  public btnNext_onClick() {
    if (!this.isBtnNextDisabled) {
      this.currentPage += 1;
    }
  }

  public updateLayout() {
    const leftDiff = this._currentPage - 1;

    this.pagesToLeftOfCurrentPageRemainder = new Array<number>();
    this.pagesToRightOfCurrentPageRemainder = new Array<number>();

    this.isBtnPrevDisabled = false;
    this.isBtnNextDisabled = false;

    if (leftDiff <= 0) {
      this.pagesToLeftOfCurrentPage = new Array<number>();
      this.isBtnPrevDisabled = true;
    } else if (leftDiff <= 2) {
      this.pagesToLeftOfCurrentPage = Array.from({ length: leftDiff }, (_, i) => i + 1);
    } else {
      this.pagesToLeftOfCurrentPage = Array.from({ length: 2 }, (_, i) => i + this._currentPage - 2);
      this.pagesToLeftOfCurrentPageRemainder = Array.from({ length: 1 }, (_, i) => i + 1);
    }

    const rightDiff = this._pageCount - this._currentPage;
    if (rightDiff <= 0) {
      this.pagesToRightOfCurrentPage = new Array<number>();
      this.isBtnNextDisabled = true;
    } else if (rightDiff <= 2) {
      this.pagesToRightOfCurrentPage = Array.from({ length: rightDiff }, (_, i) => i + this._currentPage + 1);
    } else {
      this.pagesToRightOfCurrentPage = Array.from({ length: 2 }, (_, i) => i + this._currentPage + 1);
      this.pagesToRightOfCurrentPageRemainder = Array.from({ length: 1 }, (_, i) => i + this._pageCount);
    }
  }

  private onCurrentPageChanged() {
    this.updateLayout();
    this.pageChanged.emit(this._currentPage);
  }
}
