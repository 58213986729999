import { Component, Input, OnInit } from '@angular/core';
import { OrderDirection } from '@core/public-interface/base/order-direction';

@Component({
  selector: '[app-table-cell-head]',
  templateUrl: './table-cell-head.component.html',
  styleUrls: ['./table-cell-head.component.scss'],
})
export class TableCellHeadComponent {
  public arrowSymbol = '';
  private _orderDirection: OrderDirection;

  @Input() set orderDirection(value: OrderDirection) {
    if (this._orderDirection === value) {
      return;
    }
    this._orderDirection = value;
    this.onOrderDirectionChanged();
  }

  private onOrderDirectionChanged() {
    switch (this._orderDirection) {
      case OrderDirection.none:
        this.arrowSymbol = '';
        break;
      case OrderDirection.asc:
        this.arrowSymbol = '∧';
        break;
      case OrderDirection.desc:
        this.arrowSymbol = '∨';
        break;
    }
  }
}
