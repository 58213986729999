import { Component, EventEmitter, Inject, OnInit, Renderer2 } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { contentPreviewAnimation } from '@interops/content-preview/content-preview/contentPreviewAnimation';
import { DialogRef } from '@interops/dialog/dialog.ref';
import { DIALOG_CONFIG } from '@interops/dialog/dialog.token';
import { DialogConfig } from '@interops/dialog/dialog.config';
import { ContentPreviewRef } from '@interops/content-preview/content-preview.ref';
import { CONTENT_REVIEW_CONFIG } from '@interops/content-preview/content-preview.token';
import { ContentPreviewConfig, ContentPreviewConfigType } from '@interops/content-preview/content-preview.config';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-content-preview',
  templateUrl: './content-preview.component.html',
  styleUrls: ['./content-preview.component.scss'],
  animations: [contentPreviewAnimation],
})
export class ContentPreviewComponent {
  private _isVisible = true;
  public configType = ContentPreviewConfigType;
  public url: SafeResourceUrl;

  get isVisible(): boolean {
    return this._isVisible;
  }

  public animationStateChanged = new EventEmitter<AnimationEvent>();

  constructor(
    private _contentPreviewRef: ContentPreviewRef,
    private ds: DomSanitizer,
    @Inject(CONTENT_REVIEW_CONFIG) readonly config: ContentPreviewConfig
  ) {
    this.url = ds.bypassSecurityTrustResourceUrl(config.url);
  }

  public onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  public onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  public startExitAnimation() {
    this._isVisible = false;
  }
}
