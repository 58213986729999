import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { UserService } from '@services/user.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { DialogInterop } from '@interops/dialog/dialog.interop';
import { NavigationInterop } from '@interops/navigation.interop';
import { AuthService } from '@services/auth.service';
import { LoginResponseStatusCode } from '@core/public-interface/auth/administration.login.response';
import { DialogConfig, DialogConfigType } from '@interops/dialog/dialog.config';
import { JwtInterop } from '@interops/jwt.interop';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _userService: UserService,
    private _dialogInterop: DialogInterop,
    private _navigationInterop: NavigationInterop,
    private _authService: AuthService,
    private _jwtService: JwtInterop
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.get('skip')) {
      const newHeaders = request.headers.delete('skip');
      const newReq = request.clone({ headers: newHeaders });
      return next.handle(newReq);
    }
    // const apiToken = this._userService.getToken();
    // const authReq = request;
    // if (apiToken != null) {
    //   if (!this._jwtService.isTokenExpired(apiToken)) {
    //     return next.handle(this.addTokenHeader(request, apiToken));
    //   } else {
    //     return this.refreshToken(request, next);
    //   }
    // }
    // return next.handle(authReq);
    return this.authHandler(request, next);
  }

  private refreshToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this._userService.getToken();
    const refreshTokenId = this._userService.getRefreshTokenId();
    return this._authService.refreshToken(token, refreshTokenId).pipe(
      switchMap((response) => {
        if (response.status === LoginResponseStatusCode.Success) {
          this._userService.saveToken(response.token);
          this._userService.saveRefreshTokenId(response.refreshTokenId);
          this.isRefreshing$.next(false);
          return next.handle(this.addTokenHeader(request, response.token));
        } else {
          this.isRefreshing$.next(false);
          return this.handleError();
        }
      })
    );
  }
  private authHandler(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    const apiToken = this._userService.getToken();
    if (apiToken != null) {
      if (this._jwtService.isTokenExpired(apiToken) && !this.isRefreshing$.getValue()) {
        this.isRefreshing$.next(true);
        return this.refreshToken(request, next);
      }
    }

    return this.isRefreshing$.pipe(
      filter((is) => !is),
      take(1),
      switchMap(() => {
        const apiToken = this._userService.getToken();
        return next.handle(this.addTokenHeader(request, apiToken));
      })
    );
  }
  private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }

  private handle401Error(req: HttpRequest<unknown>, next: HttpHandler, token: string): Observable<any> {
    /**
     * If we're not refreshing a token, refresh it
     * and retry the original request.
     */
    if (!this.isRefreshing$.getValue()) {
      this.isRefreshing$.next(true);

      const refreshTokenId = this._userService.getRefreshTokenId();

      if (refreshTokenId) {
        return this._authService.refreshToken(token, refreshTokenId).pipe(
          tap((value) => {
            this.isRefreshing$.next(false);
            this._userService.saveToken(value.token);
            this._userService.saveRefreshTokenId(value.refreshTokenId);
          }),
          switchMap((value) => {
            return next.handle(this.addTokenHeader(req, value.token));
          }),
          catchError((err) => {
            this.isRefreshing$.next(false);
            return this.handleError();
          })
        );
      }

      this.isRefreshing$.next(false);
      return this.handleError();
    }

    /**
     * If we're already refreshing a token, wait
     * until we get the new one and perform the
     * request with the new Access Token.
     */
    return this.isRefreshing$.pipe(
      filter((is) => !is),
      take(1),
      switchMap(() => {
        const apiToken = this._userService.getToken();
        return next.handle(this.addTokenHeader(req, apiToken));
      })
    );
  }

  private handleError(): Observable<never> {
    this._navigationInterop.logout();
    const config: DialogConfig = {
      title$: of('Error!'),
      type: DialogConfigType.error,
      confirmBtnTitle$: of('OK!'),
      cancelBtnTitle$: null,
      message$: of('The authorization token is out of date, please login.'),
    };
    this._dialogInterop.showDialog(config);
    return EMPTY;
  }
}
