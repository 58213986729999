<a (click)="btnPrev_OnClick()" [class.btn-disabled]="isBtnPrevDisabled" [class.btn-enabled]="!isBtnPrevDisabled" class="btn btn-prev">
  <span> < </span>
</a>
<ul class="list-pages">
  <ng-container *ngIf="pagesToLeftOfCurrentPage.length > 0">
    <ng-container *ngIf="pagesToLeftOfCurrentPageRemainder.length > 0;else withoutLeftRemainder">
      <li *ngFor="let i of pagesToLeftOfCurrentPageRemainder">
        <a (click)="btnPage_OnClick(i)" class="btn btn-page btn-enabled">{{i}}</a>
      </li>
      <li class="dots"><span>. . .</span></li>
      <li *ngFor="let i of pagesToLeftOfCurrentPage">
        <a (click)="btnPage_OnClick(i)" class="btn btn-page btn-enabled">{{i}}</a>
      </li>
    </ng-container>

    <ng-template #withoutLeftRemainder>
      <li *ngFor="let i of pagesToLeftOfCurrentPage">
        <a (click)="btnPage_OnClick(i)" class="btn btn-page btn-enabled">{{i}}</a>
      </li>
    </ng-template>
  </ng-container>
  <a (click)="btnPage_OnClick(currentPage)" class="btn btn-page btn-enabled btn-current">{{currentPage}}</a>

  <ng-container *ngIf="pagesToRightOfCurrentPage.length > 0">
    <ng-container *ngIf="pagesToRightOfCurrentPageRemainder.length > 0;else withoutRightRemainder">
      <li *ngFor="let i of pagesToRightOfCurrentPage">
        <a (click)="btnPage_OnClick(i)" class="btn btn-page btn-enabled">{{i}}</a>
      </li>
      <li class="dots"><span>. . .</span></li>

      <li *ngFor="let i of pagesToRightOfCurrentPageRemainder">
        <a (click)="btnPage_OnClick(i)" class="btn btn-page btn-enabled">{{i}}</a>
      </li>
    </ng-container>

    <ng-template #withoutRightRemainder>
      <li *ngFor="let i of pagesToRightOfCurrentPage">
        <a (click)="btnPage_OnClick(i)" class="btn btn-page btn-enabled">{{i}}</a>
      </li>
    </ng-template>
  </ng-container>
</ul>
<a (click)="btnNext_onClick()" [class.btn-disabled]="isBtnNextDisabled" [class.btn-enabled]="!isBtnNextDisabled" class="btn btn-next">
  <span> > </span>
</a>
