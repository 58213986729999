import { AdministrationShortProfileDto } from './administration-short-profile.dto';

export class AdministrationProfileDto extends AdministrationShortProfileDto {
  public firstName: string;
  public lastName: string;
  public email: string;

  constructor(data: any) {
    super(data);

    if (data != null) {
      this.firstName = data.firstName || null;
      this.lastName = data.lastName || null;
      this.email = data.email || null;
    }
  }
}
