import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GameQuestionType } from '@core/public-interface/games/game-question-type';
import { GameType } from '@core/public-interface/games/game.type';
import { GameTimeSamplingType } from '@core/public-interface/games/game-time-sampling.type';
import { UserService } from '@services/user.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationInterop {
  constructor(private _router: Router, private _userService: UserService) {}

  public logout(): void {
    this.showLoginMainPage();
    this._userService.signOut();
  }

  public showHomePage(): void {
    this._router.navigate(['/main']);
  }

  public showLoginPage(): void {
    this._router.navigate(['/auth/login']);
  }

  public showLoginMainPage(): void {
    this._router.navigate(['/auth/main']);
  }

  public showAddGamePage(): void {
    this._router.navigate(['/main/addOrEditGame']);
  }

  public showRoundsPage(
    gameId: string,
    gameTitle: string,
    gameType: GameType,
    gameTimeSamplingType: GameTimeSamplingType,
    hasBonusRound: boolean
  ) {
    this._router.navigate([`/main/games/${GameTimeSamplingType[gameTimeSamplingType]}/game/hosts`], {
      queryParams: {
        gameId,
        gameTitle,
        gameType,
        hasBonusRound,
      },
    });
  }

  public showResponsesScreen(
    gameId: string,
    roundId: string,
    questionType: GameQuestionType,
    gameTitle: string,
    gameTimeSamplingType: GameTimeSamplingType,
    roundIndex: number,
    gameType: GameType,
    hasBonusRound: boolean
  ) {
    this._router.navigate([`/main/games/${GameTimeSamplingType[gameTimeSamplingType]}/game/rounds/${roundIndex}/responses`], {
      queryParams: {
        gameId,
        roundId,
        questionType,
        gameTitle,
        gameType,
        hasBonusRound,
      },
    });
  }

  public showSlideButtonsScreen(
    gameId: string,
    slideId: string,
    gameTitle: string,
    roundId: string,
    gameTimeSamplingType: GameTimeSamplingType,
    roundIndex: number,
    slideIndex: number,
    hasBonusRound: boolean,
    gameType: GameType
  ) {
    this._router.navigate(
      [`/main/games/${GameTimeSamplingType[gameTimeSamplingType]}/game/rounds/${roundIndex}/slides/${slideIndex}/buttons`],
      {
        queryParams: {
          gameId,
          slideId,
          gameTitle,
          roundId,
          hasBonusRound,
          gameType,
        },
      }
    );
  }

  public showPrizeSponsorsPage(
    prizeId: string,
    gameId: string,
    gameTitle: string,
    isRoundOnly: boolean,
    roundId: string,
    gameTimeSamplingType: GameTimeSamplingType,
    prizeName: string,
    roundIndex: number,
    gameType: GameType,
    hasBonusRound: boolean
  ) {
    let href = `/main/games/${GameTimeSamplingType[gameTimeSamplingType]}/game/prizes/${prizeId}/sponsors`;

    if (isRoundOnly) {
      href = `/main/games/${GameTimeSamplingType[gameTimeSamplingType]}/game/rounds/${roundIndex}/prizes/${prizeId}/sponsors`;
    }
    this._router.navigate([href], {
      queryParams: {
        gameId,
        gameTitle,
        isRoundOnly,
        roundId,
        prizeName,
        gameType,
        hasBonusRound,
      },
    });
  }
}
