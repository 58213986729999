<div [formGroup]="parentForm" class="form-group">
  <label [for]="controlName" class="form-label">{{title}}</label>
  <div class="form-radio-button-container">
    <div *ngFor="let item of items" class="form-radio-button-input">
      <input [formControlName]="controlName" [value]="item.value" [id]="item.name" (change)="valueOnChange(item)" type="radio" />
      <label [for]="item.name">{{item.name}}</label>
    </div>
  </div>

  <app-validator-message [field]="control"></app-validator-message>
</div>
