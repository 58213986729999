import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@page-modules/base/base.component';

@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
})
export class TextareaInputComponent extends BaseComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() title: string;
  @Input() minHeight: number;
  @Input() maxlength: number;

  constructor() {
    super();
  }

  public get control(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }
}
