import { ComponentRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { ClosingButtonType, DialogRef } from '@interops/dialog/dialog.ref';
import { DIALOG_CONFIG } from '@interops/dialog/dialog.token';
import { DialogConfig } from '@interops/dialog/dialog.config';

@Injectable({
  providedIn: 'root',
})
export class DialogInterop {
  constructor(private _overlay: Overlay, private _injector: Injector) {}

  private prevDialog: DialogRef;

  public showDialog(config: DialogConfig): DialogRef {
    const overlayRef = this.createOverlay();

    const dialogRef = new DialogRef(overlayRef);

    const overlayComponent = this.attachDialogContainer(overlayRef, config, dialogRef);

    dialogRef.componentInstance = overlayComponent;

    if (this.prevDialog) {
      this.prevDialog.close(ClosingButtonType.cancel);
    }
    this.prevDialog = dialogRef;

    return dialogRef;
  }

  private createOverlay() {
    const overlayConfig = this.getOverlayConfig();

    return this._overlay.create(overlayConfig);
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this._overlay.position().global().centerHorizontally().centerVertically();

    const overlayConfig = new OverlayConfig({
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
    });

    return overlayConfig;
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: DialogConfig, dialogRef: DialogRef) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(DialogComponent, null, injector);
    const containerRef: ComponentRef<DialogComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: DialogConfig, dialogRef: DialogRef): Injector {
    const options = {
      providers: [
        {
          provide: DialogRef,
          useValue: dialogRef,
        },
        {
          provide: DIALOG_CONFIG,
          useValue: config,
        },
      ],
      parent: this._injector,
    };
    return Injector.create(options);
  }
}
