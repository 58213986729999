import { Injectable, NgZone } from '@angular/core';
import { AuthWebProxy } from '../web-proxy/auth.web-proxy';
import { EventBusService } from './event-bus.service';
import { UserService } from './user.service';
import { AdministrationLoginRequest, LoginType } from '../public-interface/auth/administration-login.request';
import { AdministrationLoginResponse, LoginResponseStatusCode } from '../public-interface/auth/administration.login.response';
import { User } from '../entities/auth/user';
import { AdministrationRefreshTokenRequest } from '@core/public-interface/auth/administration-refresh-token.request';
import { Observable } from 'rxjs';

declare let gapi: any;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _authWebProxy: AuthWebProxy,
    private _eventBusService: EventBusService,
    private _userService: UserService,
    private _ngZone: NgZone
  ) {}

  public async login(email: string, password: string): Promise<LoginResponseStatusCode> {
    const request: AdministrationLoginRequest = {
      email,
      password,
      type: LoginType.default,
      token: '',
    };

    return await this.loginImplement(request);
  }

  public refreshToken(token: string, refreshTokenId: string): Observable<AdministrationLoginResponse> {
    const request: AdministrationRefreshTokenRequest = {
      token,
      refreshTokenId,
    };

    return this._authWebProxy.refreshToken(request);
  }

  // gapi.auth2.GoogleUser;
  public async loginWIthGoogle(googleUser: any): Promise<LoginResponseStatusCode> {
    const email = googleUser.getBasicProfile().getEmail();
    const accessToken = googleUser.getAuthResponse().access_token;

    const request: AdministrationLoginRequest = {
      email,
      password: '',
      type: LoginType.google,
      token: accessToken,
    };

    return await this.loginImplement(request);
  }

  private async loginImplement(request: AdministrationLoginRequest): Promise<LoginResponseStatusCode> {
    const response = await this._authWebProxy.login(request);
    return this.handleAuthResponse(response);
  }

  private handleAuthResponse(response: AdministrationLoginResponse): LoginResponseStatusCode {
    if (response.status === LoginResponseStatusCode.Success) {
      const user = new User(response.profile);
      user.apiToken = response.token;
      this._userService.saveUser(user);
      this._userService.saveToken(user.apiToken);
      this._userService.saveRefreshTokenId(response.refreshTokenId);

      return response.status;
    } else {
      return response.status;
    }
  }
}
