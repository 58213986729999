import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@page-modules/base/base.component';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
})
export class ImgComponent {
  @Input() disabled: boolean;
  @Input() imgSrc: string;
  @Input() imgDisabledSrc: string;
  @Input() imgHeight: number;
  @Input() imgWidth: number;
  public isImgLoaded: boolean;
}
