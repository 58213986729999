import { HttpClient } from '@angular/common/http';
import { UrlProvider } from '../services/url-provider.service';

export class BaseWebProxy {
  constructor(protected _httpClient: HttpClient, protected _urlProvider: UrlProvider, protected _serviceName: string) {}

  protected apiUrl(methodName: string): string {
    return this._urlProvider.getFullUrl(this._serviceName, methodName);
  }
}
