export enum LoginType {
  default,
  google,
}

export class AdministrationLoginRequest {
  public email: string;
  public password: string;
  public token: string;
  public type: LoginType;
}
