import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retry, retryWhen } from 'rxjs/operators';
import { DialogConfig, DialogConfigType } from '@interops/dialog/dialog.config';
import { DialogInterop } from '@interops/dialog/dialog.interop';
import { NavigationInterop } from '@interops/navigation.interop';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  private retryCount = 3;
  private retryWaitMilliSeconds = 1000;

  constructor(private _dialogInterop: DialogInterop, private _navigationInterop: NavigationInterop) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((error) =>
        error.pipe(
          concatMap((error, count) => {
            const isServerError = error instanceof HttpErrorResponse;
            if (error.status == 401 || (isServerError && error.url.includes('admin/AdministrationAuthentication/RefreshToken'))) {
              return this.handleAuthError();
            }
            if (count <= this.retryCount) {
              return of(error);
            }
            if (isServerError) {
              const config: DialogConfig = {
                title$: of('Error!'),
                message$: of(`An server error occurred: ${error.message}`),
                type: DialogConfigType.error,
                confirmBtnTitle$: of('Ok'),
                cancelBtnTitle$: null,
              };
              this._dialogInterop.showDialog(config);
            }
            return throwError(error);
          }),
          delay(this.retryWaitMilliSeconds)
        )
      )
    );
  }

  private handleAuthError(): Observable<never> {
    this._navigationInterop.logout();
    const errorMsg = `The authorization token is out of date, please login.`;
    const config: DialogConfig = {
      title$: of('Error!'),
      type: DialogConfigType.error,
      confirmBtnTitle$: of('OK!'),
      cancelBtnTitle$: null,
      message$: of(errorMsg),
    };
    this._dialogInterop.showDialog(config);
    return throwError(errorMsg);
  }
}
