import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { BaseComponent } from '@page-modules/base/base.component';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent extends BaseComponent implements OnInit {
  @Input() width: number;
  @Input() height: number;
  @Input() initialsFontSize = 25;

  public avatarUrl: string;
  public initials: string;

  constructor(private _userService: UserService) {
    super();
  }

  ngOnInit(): void {
    const user = this._userService.getUser();
    this.avatarUrl = user.avatarUrl;
    this.initials = user.initials;
  }
}
