import { Injectable } from '@angular/core';
import { User } from '../entities/auth/user';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _userLoggedIn = new Subject<boolean>();

  private _userKey = 'user-key-adb88406-f983-4eb5-9149-b14476565b8f';
  private _userTokenKey = 'user-key-adb8ds06-f983-4eb5-9149-b14476565b8f';
  private _refreshTokenKey = 'refresh-token-key-f81d4922-95d9-4d7a-ba0c-ef6495df6de4';
  constructor() {
    this._userLoggedIn.next(false);
  }

  public getUser(): User {
    const value = localStorage.getItem(this._userKey);
    const user = JSON.parse(value) as User;
    return user;
  }

  public saveUser(value: User) {
    const str = JSON.stringify(value);
    localStorage.setItem(this._userKey, str);
    this.setUserLoggedIn(true);
  }

  public saveToken(token: string) {
    localStorage.setItem(this._userTokenKey, token);
  }

  public saveRefreshTokenId(refreshToken: string): void {
    localStorage.setItem(this._refreshTokenKey, refreshToken);
  }

  public getRefreshTokenId(): string | null {
    return localStorage.getItem(this._refreshTokenKey);
  }

  public getToken(): string | null {
    return localStorage.getItem(this._userTokenKey);
  }

  public signOut() {
    localStorage.clear();
    this.setUserLoggedIn(false);
  }

  private setUserLoggedIn(userLoggedIn: boolean) {
    this._userLoggedIn.next(userLoggedIn);
  }

  public getUserLoggedIn(): Observable<boolean> {
    return this._userLoggedIn.asObservable();
  }
}
