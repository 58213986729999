import { animate, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from 'ng-animate';

// export const dialogAppearanceAndHidingAnimation = trigger('dialogAppearanceAndHidingAnimation', [
//   transition(':enter', [
//     useAnimation(fadeIn, {
//       params: {timing: 0.3}
//     })
//   ]),
//   transition(':leave', [
//     useAnimation(fadeOut, {
//       params: {timing: 0.3}
//     }),
//   ])
// ]);

export const dialogAppearanceAndHidingAnimation = trigger('dialogAppearanceAndHidingAnimation', [
  state(
    'open',
    style({
      opacity: 1,
      backgroundColor: 'yellow',
    })
  ),
  state(
    'closed',
    style({
      opacity: 0.3,
      backgroundColor: 'blue',
    })
  ),
  transition('open => closed', [animate('1s')]),
  transition('closed => open', [animate('0.5s')]),
]);
