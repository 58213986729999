import { Component, OnInit } from '@angular/core';
import { IdleTimerInterop } from '@interops/idle-timer.interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'WebApp';

  constructor(private _idleTimer: IdleTimerInterop) {}

  public ngOnInit(): void {
    this._idleTimer.start();
  }
}
