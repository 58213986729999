import { OverlayRef } from '@angular/cdk/overlay';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { filter, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ContentPreviewComponent } from '@interops/content-preview/content-preview/content-preview.component';

export class ContentPreviewRef {
  public componentInstance: ContentPreviewComponent;
  private _beforeClose = new Subject<void>();
  private _afterClosed = new Subject<void>();

  constructor(private _overlayRef: OverlayRef) {}

  public close(): void {
    this.componentInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'start' && event.toState === 'void'),
        take(1)
      )
      .subscribe(() => {
        this._overlayRef.detachBackdrop();
        this._beforeClose.next();
        this._beforeClose.complete();
      });

    this.componentInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'done' && event.toState === 'void'),
        take(1)
      )
      .subscribe(() => {
        this._overlayRef.dispose();
        this._afterClosed.next();
        this._afterClosed.complete();

        this.componentInstance = null;
      });

    this.componentInstance.startExitAnimation();
  }

  afterClosed(): Observable<void> {
    return this._afterClosed.asObservable();
  }

  beforeClose(): Observable<void> {
    return this._beforeClose.asObservable();
  }
}
