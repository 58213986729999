import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { LoaderInterop } from '@interops/loader.interop';

@Component({
  selector: 'app-loader-screen',
  templateUrl: './loader-screen.component.html',
  styleUrls: ['./loader-screen.component.scss'],
})
export class LoaderScreenComponent implements OnInit, OnDestroy {
  public options: AnimationOptions;
  public isVisible = false;
  private _sub: Subscription;

  constructor(private _loaderInterop: LoaderInterop) {}

  public ngOnInit(): void {
    this.options = {
      path: '/assets/animation/loading_animation.json',
      autoplay: true,
      loop: true,
    };

    this._sub = this._loaderInterop.getObservable().subscribe((value) => {
      this.isVisible = value;
    });
  }

  public ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
