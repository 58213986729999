import { animate, query, style, transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from 'ng-animate';

export const appearanceAndHidingAnimation = trigger('appearanceAndHidingAnimation', [
  transition(':enter', [
    query(
      '.container__content',
      style({
        right: '-100%',
      })
    ),
    useAnimation(fadeIn, {
      params: { timing: 0.2 },
    }),
    query('.container__content', [
      animate(
        '300ms ease-out',
        style({
          right: '0',
        })
      ),
    ]),
  ]),
  transition(':leave', [
    query('.container__content', [
      animate(
        '300ms ease-in',
        style({
          right: '-100%',
        })
      ),
    ]),
    useAnimation(fadeOut, {
      params: { timing: 0.2 },
    }),
  ]),
]);
