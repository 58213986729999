import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@page-modules/base/base.component';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent extends BaseComponent {
  @Output() removeImage = new EventEmitter();
  @Input() btnRemoveImageVisible = true;
  private _useDefaultImage: boolean;

  constructor() {
    super();
  }

  public get isBtnRemoveVisible(): boolean {
    return !this._useDefaultImage && this.btnRemoveImageVisible;
  }

  private _imageSrc = 'assets/images/file_not_found_icon.png';

  get imageSrc(): string {
    return this._imageSrc;
  }

  @Input() set imageSrc(value: string) {
    if (this._imageSrc === value) {
      return;
    }
    if (value) {
      this._imageSrc = value;
      this._useDefaultImage = false;
      this.isBusy = true;
    } else {
      this._useDefaultImage = true;
      this._imageSrc = 'assets/images/file_not_found_icon.png';
    }
  }

  public onLoad() {
    this.isBusy = false;
  }

  protected showOrHideLoader() {
    // super.showOrHideLoader();
  }

  public btnClose_OnClick(): void {
    this.removeImage.emit();
  }
}
