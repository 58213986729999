import { ApplicationRef, Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { NavigationInterop } from '@interops/navigation.interop';
import { UserService } from '@services/user.service';
import { DialogInterop } from '@interops/dialog/dialog.interop';
import { TranslateService } from '@ngx-translate/core';
import { ClosingButtonType, DialogRef } from '@interops/dialog/dialog.ref';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DialogConfig } from '@interops/dialog/dialog.config';
import { ModalInterop } from '@interops/modal.interop';

@Injectable({
  providedIn: 'root',
})
export class IdleTimerInterop {
  private timedOut = false;
  private dialogRef: DialogRef = null;
  private sub$: Subscription;
  private _isInit: boolean;

  constructor(
    private _idle: Idle,
    private _navigationInterop: NavigationInterop,
    private _userService: UserService,
    private _dialogInterop: DialogInterop,
    private _ts: TranslateService,
    private _appRef: ApplicationRef,
    private _modalInterop: ModalInterop
  ) {
    this.init();
  }

  public start(): void {
    this.reset();
  }

  private reset(): void {
    this._idle.watch();
    this.timedOut = false;
  }

  private init(): void {
    if (this._isInit) {
      return;
    }

    this._isInit = true;

    // sets an idle timeout of 30 minutes.
    this._idle.setIdle(60 * 30);
    // sets a timeout period of 15 seconds.
    this._idle.setTimeout(15);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    const msg$ = new BehaviorSubject<string>('');
    const config = new DialogConfig(this._ts.get('Common.Warning'), msg$.asObservable(), this._ts.get('IdleTimer.Continue'));

    this._idle.onIdleEnd.subscribe(() => {
      this.dialogRef?.close(ClosingButtonType.confirm);
      this._appRef.tick(); // Workaround https://stackoverflow.com/questions/52709517/having-issues-with-ng-idle-core-onidleend-mat-dialog
      this.reset();
    });

    this._idle.onTimeout.subscribe(() => {
      const msg: string = this._ts.instant('IdleTimer.TimedOut');
      msg$.next(msg);
      this.timedOut = true;
      this.dialogRef?.close(ClosingButtonType.cancel);
    });

    this._idle.onIdleStart.subscribe(() => {
      const msg: string = this._ts.instant('IdleTimer.GoneIdle');
      msg$.next(msg);

      this.dialogRef = this._dialogInterop.showDialog(config);
      this.sub$ = this.dialogRef.afterClosed().subscribe((value) => {
        if (value.buttonType === ClosingButtonType.confirm) {
          this.reset();
        } else {
          this._modalInterop.hide();
          this._navigationInterop.logout();
        }
      });
    });

    this._idle.onTimeoutWarning.subscribe((countdown: any) => {
      const msg: string = this._ts.instant('IdleTimer.StartCountdown', { countdown });
      msg$.next(msg);
    });

    this._userService.getUserLoggedIn().subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        this._idle.watch();
        this.timedOut = false;
      } else {
        this._idle.stop();
      }
    });
  }
}
