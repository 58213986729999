import { AdministrationProfileDto } from '@core/public-interface/auth/administration-profile.dto';

export class AdministrationLoginResponse {
  public token: string;
  public refreshTokenId: string;
  public status: LoginResponseStatusCode;
  public profile: AdministrationProfileDto;
}

export enum LoginResponseStatusCode {
  None = 0,
  Success = 1,
  UserNotFound,
}
