import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@page-modules/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

interface Config {
  key: string;
  message: string;
}

@Component({
  selector: 'app-validator-message',
  templateUrl: './validator-message.component.html',
  styleUrls: ['./validator-message.component.scss'],
})
export class ValidatorMessageComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() field: FormControl;
  @Input() fieldName: string;
  private _filedNameSub: Subscription;

  constructor(private _ts: TranslateService) {
    super();
  }

  public ngOnInit(): void {
    this._filedNameSub = this._ts.get('Errors.FiledName').subscribe((value) => (this.fieldName = value));
  }

  public ngOnDestroy(): void {
    this._filedNameSub?.unsubscribe();
  }

  public get validatorMessages(): string[] {
    const field = this.field;
    if (!field || !field.errors) {
      return [];
    }
    const errors: string[] = [];

    const configs: Config[] = [
      { key: 'required', message: this._ts.instant('Errors.IsNull', { fieldName: this.fieldName }) },
      { key: 'requiredTrue', message: this._ts.instant('Errors.IsNotPositive', { fieldName: this.fieldName }) },
      { key: 'email', message: this._ts.instant('Errors.IsNotEmail', { fieldName: this.fieldName }) },
    ];

    if (Object.prototype.hasOwnProperty.call(field.errors, 'customError')) {
      const errorMsg =
        typeof field.errors.customError === 'string' && field.errors.customError.length > 0 ? field.errors.customError : 'Error!';

      configs.push({ key: 'customError', message: errorMsg });
    }

    if (Object.prototype.hasOwnProperty.call(field.errors, 'minlength')) {
      configs.push({
        key: 'minlength',
        message: this._ts.instant('Errors.IsNotMinlength', { minLength: field.errors.minlength.requiredLength }),
      });
    }

    if (Object.prototype.hasOwnProperty.call(field.errors, 'maxlength')) {
      configs.push({
        key: 'maxlength',
        message: this._ts.instant('Errors.IsNotMaxlength', { maxLength: field.errors.maxlength.requiredLength }),
      });
    }

    if (Object.prototype.hasOwnProperty.call(field.errors, 'min')) {
      configs.push({
        key: 'min',
        message: this._ts.instant('Errors.IsNotMinValue', { min: field.errors.min.min, actual: field.errors.min.actual }),
      });
    }

    if (Object.prototype.hasOwnProperty.call(field.errors, 'max')) {
      configs.push({
        key: 'max',
        message: this._ts.instant('Errors.IsNotMaxValue', { max: field.errors.max.max, actual: field.errors.max.actual }),
      });
    }

    if (Object.prototype.hasOwnProperty.call(field.errors, 'isNotImage')) {
      configs.push({
        key: 'isNotImage',
        message: this._ts.instant('Errors.IsNotImage'),
      });
    }

    if (Object.prototype.hasOwnProperty.call(field.errors, 'isNotUrl')) {
      configs.push({ key: 'isNotUrl', message: this._ts.instant('Errors.IsNotUrl') });
    }

    if (Object.prototype.hasOwnProperty.call(field.errors, 'isNotYoutubeOrVimeo')) {
      configs.push({ key: 'isNotYoutubeOrVimeo', message: this._ts.instant('Errors.IsNotYoutubeOrVimeo') });
    }

    if (Object.prototype.hasOwnProperty.call(field.errors, 'imageRequired')) {
      configs.push({ key: 'imageRequired', message: this._ts.instant('Errors.IsNull', { fieldName: this.fieldName }) });
    }

    if (Object.prototype.hasOwnProperty.call(field.errors, 'isNotPhoneNumber')) {
      configs.push({ key: 'isNotPhoneNumber', message: this._ts.instant('Errors.IsNotPhoneNumber', { fieldName: this.fieldName }) });
    }

    if (Object.prototype.hasOwnProperty.call(field.errors, 'isNotInteger')) {
      configs.push({ key: 'isNotInteger', message: this._ts.instant('Errors.IsNotInteger', { fieldName: this.fieldName }) });
    }

    // if (field.errors.hasOwnProperty('dateMinimum')) {
    //   configs.push({
    //     key: 'dateMinimum',
    //     message: `Minimum date ${field.errors.dateMinimum.dateMinimum}, actual date ${field.errors.dateMinimum.actual}`
    //   });
    // }

    Object.keys(field.errors).forEach((error: string) => {
      errors.push(configs.find((value) => value.key === error)?.message);
    });

    return errors;
  }
}
