import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from 'ng-animate';

export const contentPreviewAnimation = trigger('contentPreviewAnimation', [
  transition(':enter', [
    useAnimation(fadeIn, {
      params: { timing: 0.3 },
    }),
  ]),
  transition(':leave', [
    useAnimation(fadeOut, {
      params: { timing: 0.3 },
    }),
  ]),
]);
