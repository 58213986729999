<div [formGroup]="parentForm" class="form-group">
  <label [for]="controlName" class="form-label">{{title}}</label>
  <input
    [autocomplete]="autocomplete"
    [formControlName]="controlName"
    [id]="controlName"
    [max]="max"
    [min]="min"
    [name]="controlName"
    [placeholder]="placeholder"
    [type]="type"
    [maxlength]="maxlength"
    class="form-text-input" />

  <app-validator-message [field]="control"></app-validator-message>
</div>
