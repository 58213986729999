import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-ein-input',
  templateUrl: './ein-input.component.html',
  styleUrls: ['./ein-input.component.scss'],
})
export class EinInputComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() title: string;

  public get control(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }
}
