import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@page-modules/base/base.component';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
})
export class CheckboxInputComponent extends BaseComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() title: string;

  constructor() {
    super();
  }

  public get control(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }
}
