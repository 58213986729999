import { Injectable, Renderer2, RendererFactory2, RendererStyleFlags2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowScrollingInterop {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public disable(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden', RendererStyleFlags2.Important);
  }

  public enable(): void {
    this.renderer.removeStyle(document.body, 'overflow');
  }
}
