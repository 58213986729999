import { OverlayRef } from '@angular/cdk/overlay';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { filter, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

export class CloseDialogArg {
  constructor(public buttonType: ClosingButtonType) {}
}

export enum ClosingButtonType {
  cancel,
  confirm,
}

export class DialogRef {
  public componentInstance: DialogComponent;
  private _beforeClose = new Subject<void>();
  private _afterClosed = new Subject<CloseDialogArg>();
  private isClosed: boolean;

  constructor(private _overlayRef: OverlayRef) {}

  public close(buttonType: ClosingButtonType): void {
    if (this.isClosed) {
      return;
    }
    this.isClosed = true;
    this.componentInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'start' && event.toState === 'void'),
        take(1)
      )
      .subscribe(() => {
        this._overlayRef.detachBackdrop();
        this._beforeClose.next();
        this._beforeClose.complete();
      });

    this.componentInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'done' && event.toState === 'void'),
        take(1)
      )
      .subscribe(() => {
        this._overlayRef.dispose();
        this._afterClosed.next(new CloseDialogArg(buttonType));
        this._afterClosed.complete();

        this.componentInstance = null;
      });

    this.componentInstance.startExitAnimation();
  }

  afterClosed(): Observable<CloseDialogArg> {
    return this._afterClosed.asObservable();
  }

  beforeClose(): Observable<void> {
    return this._beforeClose.asObservable();
  }
}
