import { Injectable, Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ModalInterop {
  private _settings$ = new Subject<ModalSettings>();

  public getObservable(): Observable<ModalSettings> {
    return this._settings$.asObservable();
  }

  public show(settings: ModalSettings) {
    this._settings$.next(settings);
  }

  public hide() {
    this._settings$.next(null);
  }
}

export class ModalSettings {
  public componentType: Type<any>;
  public arg?: {
    type: Type<any>;
    value: any;
  };
  public activatedRoute: ActivatedRoute;
}
