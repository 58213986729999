import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdministrationLoginRequest } from '../public-interface/auth/administration-login.request';
import { AdministrationLoginResponse, LoginResponseStatusCode } from '../public-interface/auth/administration.login.response';
import { UrlProvider } from '../services/url-provider.service';
import { BaseWebProxy } from './base.web-proxy';
import { map } from 'rxjs/operators';
import { AdministrationProfileDto } from '@core/public-interface/auth/administration-profile.dto';
import { AdministrationRefreshTokenRequest } from '@core/public-interface/auth/administration-refresh-token.request';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthWebProxy extends BaseWebProxy {
  constructor(httpClient: HttpClient, urlProvider: UrlProvider) {
    super(httpClient, urlProvider, 'AdministrationAuthentication');
  }

  public login(loginRequest: AdministrationLoginRequest): Promise<AdministrationLoginResponse> {
    return this._httpClient
      .post<AdministrationLoginResponse>(this.apiUrl('login'), loginRequest)
      .pipe(
        map((value) => {
          const res: AdministrationLoginResponse = {
            token: value.token || null,
            status: value.status || LoginResponseStatusCode.None,
            profile: value.profile == null ? new AdministrationProfileDto({}) : new AdministrationProfileDto(value.profile),
            refreshTokenId: value.refreshTokenId || '00000000-0000-0000-0000-000000000000',
          };
          return res;
        })
      )
      .toPromise();
  }

  public getUsers(emailFilter: string, numberOfUsers: string): Promise<Array<AdministrationProfileDto>> {
    const params = new HttpParams().set('emailFilter', emailFilter).set('numberOfUsers', numberOfUsers);
    return this._httpClient
      .get<Array<AdministrationProfileDto>>(this.apiUrl('GetUsers'), { params })
      .pipe(
        map((array) => {
          return array != null ? array.map((item) => new AdministrationProfileDto(item)) : [];
        })
      )
      .toPromise();
  }

  public getUsersByUserName(userNameFilter: string, numberOfUsers: string): Promise<Array<AdministrationProfileDto>> {
    const params = new HttpParams().set('userNameFilter', userNameFilter).set('numberOfUsers', numberOfUsers);
    return this._httpClient
      .get<Array<AdministrationProfileDto>>(this.apiUrl('GetUsersByUserName'), { params })
      .pipe(
        map((array) => {
          return array != null ? array.map((item) => new AdministrationProfileDto(item)) : [];
        })
      )
      .toPromise();
  }

  public isUserExist(email: string): Promise<boolean> {
    const params = new HttpParams().set('email', email);
    return this._httpClient.get<boolean>(this.apiUrl('IsUserExist'), { params }).toPromise();
  }

  public refreshToken(refreshTokenRequest: AdministrationRefreshTokenRequest): Observable<AdministrationLoginResponse> {
    const headers = new HttpHeaders({ skip: 'true' });
    const options = {
      headers: headers,
    };
    return this._httpClient.post<AdministrationLoginResponse>(this.apiUrl('RefreshToken'), refreshTokenRequest, options).pipe(
      map((value) => {
        const res: AdministrationLoginResponse = {
          token: value.token || null,
          status: value.status || LoginResponseStatusCode.None,
          profile: value.profile == null ? new AdministrationProfileDto({}) : new AdministrationProfileDto(value.profile),
          refreshTokenId: value.refreshTokenId || '00000000-0000-0000-0000-000000000000',
        };
        return res;
      })
    );
  }
}
