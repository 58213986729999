import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@page-modules/base/base.component';

@Component({
  selector: 'app-medium-rounded-button',
  templateUrl: './medium-rounded-button.component.html',
  styleUrls: ['./medium-rounded-button.component.scss'],
})
export class MediumRoundedButtonComponent extends BaseComponent {
  @Input() isPrimary: boolean;
  @Input() title: string;
  @Input() type: string;
  @Input() parentForm: UntypedFormGroup;
  @Input() disabled: boolean;

  constructor() {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  btnClick() {}
}
