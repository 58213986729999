export class User {
  public id: string;
  public userName: string;
  public avatarUrl: string;
  public initials: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public apiToken: string;

  constructor(data: any) {
    this.id = data.id || '00000000-0000-0000-0000-000000000000';
    this.userName = data.userName || null;
    this.avatarUrl = data.avatarUrl || null;
    this.initials = data.initials || null;
    this.firstName = data.firstName || null;
    this.lastName = data.lastName || null;
    this.email = data.email || null;
    this.apiToken = data.apiToken || null;
  }
}
