<div
  (@contentPreviewAnimation.done)="onAnimationDone($event)"
  (@contentPreviewAnimation.start)="onAnimationStart($event)"
  *ngIf="isVisible"
  [@contentPreviewAnimation]
  class="container">
  <img [src]="url" *ngIf="config.type === configType.image" class="img" />

  <iframe
    class="video"
    [src]="url"
    *ngIf="config.type === configType.video"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen>
  </iframe>
</div>
