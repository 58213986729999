import { Component, OnDestroy, OnInit } from '@angular/core';
import { Breadcrumb, BreadcrumsInterop } from '@interops/breadcrums.interop';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  public breadcrumbs: Array<Breadcrumb>;
  private _sub: Subscription;

  constructor(private _breadcrumsInterop: BreadcrumsInterop) {}

  public ngOnInit(): void {
    this._sub = this._breadcrumsInterop.getObservable().subscribe((breadcrumbs) => this.updateBreadcrumbs(breadcrumbs));
  }

  public ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private updateBreadcrumbs(breadcrumbs: Array<Breadcrumb>) {
    this.breadcrumbs = breadcrumbs;
  }
}
