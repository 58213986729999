<div [formGroup]="parentForm" class="form-group">
  <label [for]="controlName" class="form-label">{{title}}</label>
  <input
    [formControlName]="controlName"
    [id]="controlName"
    [name]="controlName"
    class="form-text-input"
    mask="00-0000000"
    placeholder="00-0000000"
    type="text" />

  <app-validator-message [field]="control"></app-validator-message>
</div>
