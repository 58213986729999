import { Observable } from 'rxjs';

export class DialogConfig {
  public title$: Observable<string>;
  public message$: Observable<string>;
  public type: DialogConfigType = DialogConfigType.warning;
  public confirmBtnTitle$: Observable<string>;
  public cancelBtnTitle$: Observable<string> = null;

  constructor(
    title$: Observable<string>,
    message$: Observable<string>,
    confirmBtnTitle$: Observable<string>,
    cancelBtnTitle$: Observable<string> = null,
    type: DialogConfigType = DialogConfigType.warning
  ) {
    this.title$ = title$;
    this.message$ = message$;
    this.confirmBtnTitle$ = confirmBtnTitle$;
    this.cancelBtnTitle$ = cancelBtnTitle$;
    this.type = type;
  }
}

export enum DialogConfigType {
  error,
  warning,
  success,
  info,
}
