/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ContentChild, ElementRef, forwardRef, Input, Provider, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

const FILE_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileInputComponent),
  multi: true,
};

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [FILE_INPUT_VALUE_ACCESSOR],
})
export class FileInputComponent implements ControlValueAccessor {
  @Input() btnTitle: string;
  @Input() accept: string;
  @Input() control: AbstractControl;
  @ViewChild('fileInput') fileInput: ElementRef;

  private _file: File;

  private _formGroup: UntypedFormGroup = new UntypedFormGroup({});

  get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }

  public onFileChanged(event: Event) {
    const target = event.target as HTMLInputElement;
    this._file = target.files[0];
    this.onTouched();
    this.onChange(this._file);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {}

  public writeValue(obj: File): void {
    this._file = obj;
    if (this.fileInput != null) {
      this.fileInput.nativeElement.value = this._file;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (value: File) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};
}
