import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { BaseComponent } from '@page-modules/base/base.component';

export enum ButtonStyleType {
  primary,
  secondary,
  lightGray,
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends BaseComponent implements AfterViewInit {
  @Input() disabled = false;
  @Input() title: string;
  @Input() imgSrc: string;
  @Input() imgDisabledSrc: string;
  @Input() imgWidth: number;
  @Input() imgHeight: number;
  @Input() imgMarginRight: number;
  @Input() styleType: ButtonStyleType;
  @ViewChild('btn') btn: ElementRef;

  @Output() btnClick = new EventEmitter();

  constructor(private _renderer: Renderer2) {
    super();
  }

  ngAfterViewInit(): void {
    this.setupBtnStyle();
  }

  public btn_OnClick() {
    this.btnClick.emit();
  }

  private setupBtnStyle() {
    switch (this.styleType) {
      case ButtonStyleType.primary:
        this._renderer.addClass(this.btn.nativeElement, 'button-primary');
        break;
      case ButtonStyleType.secondary:
        this._renderer.addClass(this.btn.nativeElement, 'button-secondary');
        break;
      case ButtonStyleType.lightGray:
        this._renderer.addClass(this.btn.nativeElement, 'button-light-gray');
        break;
      default:
        this._renderer.addClass(this.btn.nativeElement, 'button-primary');
    }
  }
}
