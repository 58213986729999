import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UrlProvider {
  private _apiBaseUrlTrimmed = '';

  constructor() {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  private _apiBaseUrl = '';

  get apiBaseUrl(): string {
    return this._apiBaseUrl;
  }

  set apiBaseUrl(value: string) {
    this._apiBaseUrl = value;
    if (this.apiBaseUrl.trim().length === 0) {
      this._apiBaseUrlTrimmed = '';
    } else {
      if (this.apiBaseUrl[this.apiBaseUrl.length] === '/') {
        this._apiBaseUrlTrimmed = this.apiBaseUrl.slice(0, -1);
      }
      this._apiBaseUrlTrimmed = this.apiBaseUrl;
    }
  }

  getFullUrl(serviceName: string, methodName: string): string {
    if (serviceName.trim().length === 0) {
      return `${this._apiBaseUrlTrimmed}/${methodName}`;
    }
    return `${this._apiBaseUrlTrimmed}/${serviceName}/${methodName}`;
  }
}
