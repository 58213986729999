<p class="title header header-medium">{{'Main-nav.Games.Title' | translate }}</p>
<div class="nav-wrapper">
  <nav class="games-nav">
    <ul class="links-list main-links-list">
      <li class="item item-first" routerLinkActive="link-active">
        <a class="href link" routerLink="/main/games/current"> {{'Main-nav.Games.Current' | translate }} </a>
      </li>
      <li class="item" routerLinkActive="link-active">
        <a class="href link" routerLink="/main/games/future"> {{'Main-nav.Games.Future' | translate }} </a>
      </li>
      <li class="item" routerLinkActive="link-active">
        <a class="href link" routerLink="/main/games/previous"> {{'Main-nav.Games.Previous' | translate }} </a>
      </li>
      <li class="item" routerLinkActive="link-active">
        <a class="href link" routerLink="/main/games/completed"> {{'Main-nav.Games.Completed' | translate }} </a>
      </li>
    </ul>
  </nav>
</div>

<p class="title header header-medium">{{'Main-nav.Administrators.Title' | translate }}</p>
<div class="nav-wrapper">
  <nav class="games-nav">
    <ul class="links-list main-links-list">
      <li class="item item-first" routerLinkActive="link-active">
        <a class="href link" routerLink="/main/admin/administrators"> {{'Main-nav.Administrators.Administrators' | translate }} </a>
      </li>
      <li class="item item-first" routerLinkActive="link-active">
        <a class="href link" routerLink="/main/admin/updates"> {{'Main-nav.Administrators.NewsAndArticles' | translate }} </a>
      </li>
      <li class="item" routerLinkActive="link-active">
        <a class="href link" routerLink="/main/admin/slide-button-icons"> {{'Main-nav.Administrators.SlideButtonIcons' | translate }} </a>
      </li>
      <li class="item" routerLinkActive="link-active">
        <a class="href link" routerLink="/main/admin/user-prizes"> {{'Main-nav.Administrators.UserPrizes' | translate }} </a>
      </li>
    </ul>
  </nav>
</div>
