import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from './components/text-input/text-input.component';
import { ValidatorMessageComponent } from './components/validator-message/validator-message.component';
import { KeysPipe } from './pipes/keys.pipe';
import { BigRoundedButtonComponent } from './components/big-rounded-button/big-rounded-button.component';
import { MediumRoundedButtonComponent } from './components/medium-rounded-button/medium-rounded-button.component';
import { ButtonComponent } from './components/button/button.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ImgComponent } from './components/img/img.component';
import { ModalComponent } from './components/modal/modal.component';
import { TextareaInputComponent } from './components/textarea-input/textarea-input.component';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CheckboxInputComponent } from './components/checkbox-input/checkbox-input.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DialogComponent } from './components/dialog/dialog.component';
import { PortalModule } from '@angular/cdk/portal';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TimesPipe } from '@shared/pipes/times.pipe ';
import { TableCellHeadComponent } from './components/table-cell-head/table-cell-head.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './components/loader/loader.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { LoaderScreenComponent } from './components/loader-screen/loader-screen.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { InputWithDelayDirective } from './directives/input-with-delay.directive';
import { EinInputComponent } from './components/ein-input/ein-input.component';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import { ContentPreviewComponent } from '@interops/content-preview/content-preview/content-preview.component';
import { RadioButtonsInputComponent } from './components/radio-buttons-input/radio-buttons-input.component';
import { FormHelpers } from '@shared/helpers/form-helpers';

export function playerFactory() {
  return player;
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    TextInputComponent,
    ValidatorMessageComponent,
    KeysPipe,
    TimesPipe,
    BigRoundedButtonComponent,
    MediumRoundedButtonComponent,
    ButtonComponent,
    AvatarComponent,
    TruncatePipe,
    ImgComponent,
    ModalComponent,
    TextareaInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    DialogComponent,
    PaginationComponent,
    TableCellHeadComponent,
    BreadcrumbsComponent,
    LoaderComponent,
    FileInputComponent,
    ImagePreviewComponent,
    LoaderScreenComponent,
    InputWithDelayDirective,
    EinInputComponent,
    ContentPreviewComponent,
    RadioButtonsInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    OverlayModule,
    PortalModule,
    RouterModule,
    LottieModule.forRoot({ player: playerFactory }),
    TranslateModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    OverlayModule,
    ReactiveFormsModule,
    TextInputComponent,
    ValidatorMessageComponent,
    KeysPipe,
    TimesPipe,
    BigRoundedButtonComponent,
    MediumRoundedButtonComponent,
    ButtonComponent,
    AvatarComponent,
    TruncatePipe,
    ImgComponent,
    ModalComponent,
    TextareaInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    PaginationComponent,
    TableCellHeadComponent,
    BreadcrumbsComponent,
    LoaderComponent,
    FileInputComponent,
    ImagePreviewComponent,
    LoaderScreenComponent,
    InputWithDelayDirective,
    EinInputComponent,
    TranslateModule,
    RadioButtonsInputComponent,
  ],
  providers: [provideEnvironmentNgxMask(maskConfig)],
})
export class SharedModule {}
