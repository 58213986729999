import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterop {
  private _stream$ = new Subject<boolean>();

  public getObservable(): Observable<boolean> {
    return this._stream$.asObservable();
  }

  public show() {
    this._stream$.next(true);
  }

  public hide() {
    this._stream$.next(false);
  }
}
