import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DialogConfig, DialogConfigType } from '@interops/dialog/dialog.config';
import { DialogInterop } from '@interops/dialog/dialog.interop';
import { NavigationInterop } from '@interops/navigation.interop';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private _dialogInterop: DialogInterop, private _navigationInterop: NavigationInterop) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        (event) => {},
        (error) => {
          if (error.error instanceof ErrorEvent) {
            const config: DialogConfig = {
              title$: of('Error!'),
              message$: of(`An client error occurred: ${error.error.message}`),
              type: DialogConfigType.error,
              confirmBtnTitle$: of('Ok'),
              cancelBtnTitle$: null,
            };
            this._dialogInterop.showDialog(config);
          }
        }
      )
    );
  }
}
