import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjectorInterop } from '@interops/app.injector.interop';

if (environment.production || environment.uat) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    AppInjectorInterop.setInjector(moduleRef.injector);
  })
  .catch((err) => console.error(err));
