<div class="form-group container">
  <ng-content select="app-image-preview"></ng-content>

  <input #fileInput (change)="onFileChanged($event)" [accept]="accept" class="file-input" type="file" />

  <div class="btn-container">
    <app-medium-rounded-button
      (click)="fileInput.click()"
      [isPrimary]="false"
      [parentForm]="formGroup"
      [title]="btnTitle"
      [type]="'button'"
      class="btn"
      [disabled]="control.disabled">
    </app-medium-rounded-button>

    <ng-content></ng-content>
  </div>
</div>

<app-validator-message [field]="control" [fieldName]="'Image'"></app-validator-message>
