export class ContentPreviewConfig {
  public url: string;
  public type: ContentPreviewConfigType;

  constructor(url: string, type: ContentPreviewConfigType) {
    this.url = url;
    this.type = type;
  }
}

export enum ContentPreviewConfigType {
  image,
  video,
}
