import { AfterViewInit, Component, forwardRef, Input, OnInit, Provider, QueryList, ViewChildren } from '@angular/core';
import { FileInputComponent } from '@shared/components/file-input/file-input.component';
import { AbstractControl, ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

const RADIO_BUTTONS_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioButtonsInputComponent),
  multi: true,
};

export class RadioButtonItem {
  constructor(public value: any, public name: string) {}
}

class RadioButtonModel {
  constructor(public value: any, public name: string, public checked: boolean) {}
}

@Component({
  selector: 'app-radio-buttons-input',
  templateUrl: './radio-buttons-input.component.html',
  styleUrls: ['./radio-buttons-input.component.scss'],
  providers: [RADIO_BUTTONS_INPUT_VALUE_ACCESSOR],
})
export class RadioButtonsInputComponent implements ControlValueAccessor, OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() title: string;
  @Input() buttons: Array<RadioButtonItem>;
  public items: Array<RadioButtonModel>;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (value: string) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};

  public get control(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  public ngOnInit(): void {
    if (this.buttons?.length > 0) {
      this.items = new Array<RadioButtonModel>();
      this.buttons.forEach((button) => this.items.push(new RadioButtonModel(button.value, button.name, false)));
    }
  }

  public valueOnChange(item: RadioButtonModel): void {
    this.onTouched();
    this.onChange(item.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    if (obj === 'string' || obj instanceof String) {
      this.items.forEach((value) => (value.checked = false));
      const radioButtonModel: RadioButtonModel = this.items.find((value) => value.value === obj);
      radioButtonModel.checked = true;
    }
  }
}
