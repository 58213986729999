<div [formGroup]="parentForm" class="container">
  <button
    (click)="btnClick()"
    [class.button-primary]="isPrimary"
    [class.button-secondary]="!isPrimary"
    [type]="type"
    [disabled]="disabled"
    class="btn button button-medium button-medium-rounded">
    <p class="btn__title">{{title}}</p>
  </button>
</div>
