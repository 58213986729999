<div
  #contentContainer
  (@dialogAppearanceAndHidingAnimation.done)="onAnimationDone($event)"
  (@dialogAppearanceAndHidingAnimation.start)="onAnimationStart($event)"
  *ngIf="isVisible"
  [@dialogAppearanceAndHidingAnimation]
  class="content-container">
  <div class="title-container"><label class="title">{{config.title$ | async}}</label></div>
  <div class="message-container"><label class="message">{{config.message$ | async}}</label></div>

  <div class="btn-container">
    <button (click)="btnCancel_OnClick()" *ngIf="!!config.cancelBtnTitle$" class="btn">{{config.cancelBtnTitle$ | async}}</button>
    <button (click)="btnConfirm_OnClick()" class="btn">{{config.confirmBtnTitle$ | async}}</button>
  </div>
</div>
