import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Directive({
  selector: '[appInputWithDelay]',
})
export class InputWithDelayDirective {
  @Input() useDelay = true;
  @Output() valueChanged = new EventEmitter<string>();
  @Output() stopDelayTimer = new EventEmitter<string>();

  private _isFirstInput: boolean;
  private _timerSub: Subscription;
  private _value: string;

  private set value(value: string) {
    if (this._value === value) {
      return;
    }
    this._value = value;
    this.onValueChanged(this._value);
  }

  public onValueChanged(value: string) {
    if (this.useDelay) {
      if (value) {
        this.resetTimer();
      } else {
        this.stopTimerImplement();
      }
    } else {
      this.valueChanged.emit(value);
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    const value = event.target.value;
    if (typeof value === 'string') {
      this.value = value;
    }
  }

  private setTimer() {
    const timer$ = interval(this._isFirstInput ? 100 : 300);
    this._isFirstInput = false;
    this._timerSub = timer$.subscribe((value) => this.onTimed());
  }

  private onTimed() {
    this._timerSub?.unsubscribe();
    this.valueChanged.emit(this._value);
  }

  private resetTimer() {
    this._timerSub?.unsubscribe();
    this.setTimer();
  }

  private stopTimerImplement() {
    this._timerSub?.unsubscribe();
    this._isFirstInput = true;
    this.stopDelayTimer.emit();
  }
}
