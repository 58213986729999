import { LoaderInterop } from '@interops/loader.interop';
import { AppInjectorInterop } from '@interops/app.injector.interop';

export class BaseComponent {
  private _isBusy: boolean;

  get isBusy(): boolean {
    return this._isBusy;
  }

  set isBusy(value: boolean) {
    if (this._isBusy === value) {
      return;
    }
    this._isBusy = value;
    this.onIsBusyChanged();
  }

  private _loaderInterop: LoaderInterop = null;

  protected get loaderInterop(): LoaderInterop {
    if (this._loaderInterop === null) {
      this._loaderInterop = AppInjectorInterop.getInjector().get(LoaderInterop);
    }
    return this._loaderInterop;
  }

  protected loadDataImplement(): Promise<any> {
    return null;
  }

  protected onIsBusyChanged() {
    this.showOrHideLoader();
  }

  protected showOrHideLoader() {
    if (this.isBusy) {
      this.loaderInterop.show();
    } else {
      this.loaderInterop.hide();
    }
  }
}
