export class AdministrationShortProfileDto {
  public id: string;
  public userName: string;
  public avatarUrl: string;
  public initials: string;

  constructor(data: any) {
    if (data != null) {
      this.id = data.id || '00000000-0000-0000-0000-000000000000';
      this.userName = data.userName || null;
      this.avatarUrl = data.avatarUrl || null;
      this.initials = data.initials || null;
    }
  }
}
