import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { GameTimeSamplingType } from '@core/public-interface/games/game-time-sampling.type';
import { GameType } from '@core/public-interface/games/game.type';
import { filter } from 'rxjs/operators';
import { GameQuestionType } from '@core/public-interface/games/game-question-type';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumsInterop {
  private timeSamplingType: GameTimeSamplingType;
  private _gameId: string;
  private _gameTitle: string;
  private _gameType: GameType;
  private _hasBonusRound: boolean;
  private _roundIndex: number;
  private _roundId: string;
  private _questionType: GameQuestionType;
  private _slideId: string;
  private _slideIndex: number;
  private _isRoundOnly: boolean;

  private _subject$ = new Subject<Array<Breadcrumb>>();

  public get timeSamplingTypeStr(): string {
    return GameTimeSamplingType[this.timeSamplingType];
  }
  public getObservable(): Observable<Array<Breadcrumb>> {
    return this._subject$.asObservable();
  }

  public show(breadcrumbs: Array<Breadcrumb>) {
    this._subject$.next(breadcrumbs);
  }

  public mainGamePage(route: ActivatedRoute): Breadcrumb {
    this.parserRoute(route);
    return {
      title: 'Games',
      href: `/main/games/${this.timeSamplingTypeStr}`,
    };
  }

  public gameHostsPage(route: ActivatedRoute): Breadcrumb {
    this.parserRoute(route);
    return {
      title: this._gameTitle,
      href: `/main/games/${this.timeSamplingTypeStr}/game/hosts`,
      queryParams: {
        gameId: this._gameId,
        gameTitle: this._gameTitle,
        gameType: this._gameType,
        hasBonusRound: this._hasBonusRound,
      },
    };
  }
  public mainRoundsPage(route: ActivatedRoute): Breadcrumb {
    this.parserRoute(route);
    return {
      title: 'Rounds',
      href: `/main/games/${this.timeSamplingTypeStr}/game/rounds/`,
      queryParams: {
        gameId: this._gameId,
        gameTitle: this._gameTitle,
        gameType: this._gameType,
        hasBonusRound: this._hasBonusRound,
      },
    };
  }

  public roundPage(route: ActivatedRoute): Breadcrumb {
    this.parserRoute(route);
    return {
      title: this._isRoundOnly ? `Round ${this._roundIndex}` : 'Round',
      href: `/main/games/${this.timeSamplingTypeStr}/game/rounds/`,
      queryParams: {
        gameId: this._gameId,
        gameTitle: this._gameTitle,
        gameType: this._gameType,
        hasBonusRound: this._hasBonusRound,
      },
    };
  }
  public roundResponsePage(route: ActivatedRoute): Breadcrumb {
    this.parserRoute(route);
    return {
      title: `Round ${this._roundIndex} Responses`,
      href: `/main/games/${this.timeSamplingTypeStr}/game/rounds/${this._roundIndex}/responses`,
      queryParams: {
        gameId: this._gameId,
        roundId: this._roundId,
        questionType: this._questionType,
        gameTitle: this._gameTitle,
        gameType: this._gameType,
        hasBonusRound: this._hasBonusRound,
      },
    };
  }

  public slidePage(route: ActivatedRoute): Breadcrumb {
    this.parserRoute(route);
    return {
      title: `Round ${this._roundIndex} Slides`,
      href: `/main/games/${this.timeSamplingTypeStr}/game/rounds/${this._roundIndex}/slides`,
      queryParams: {
        gameId: this._gameId,
        roundId: this._roundId,
        gameTitle: this._gameTitle,
        gameType: this._gameType,
        hasBonusRound: this._hasBonusRound,
      },
    };
  }
  public slideButtonsPage(route: ActivatedRoute): Breadcrumb {
    this.parserRoute(route);
    return {
      title: `Slide${this._slideIndex} Buttons`,
      href: `/main/games/${this.timeSamplingTypeStr}/game/rounds/${this._roundIndex}/slides/${this._slideIndex}/buttons`,
      queryParams: {
        gameId: this._gameId,
        slideId: this._slideId,
        gameTitle: this._gameTitle,
        gameType: this._gameType,
        roundId: this._roundId,
        hasBonusRound: this._hasBonusRound,
      },
    };
  }
  public prizePage(route: ActivatedRoute): Breadcrumb {
    this.parserRoute(route);
    return {
      title: this._isRoundOnly ? `Round ${this._roundIndex} Prizes` : 'Prizes',
      href: `/main/games/${this.timeSamplingTypeStr}/game/rounds/${this._roundIndex}/prizes`,
      queryParams: {
        gameId: this._gameId,
        roundId: this._roundId,
        gameTitle: this._gameTitle,
        gameType: this._gameType,
        isRoundOnly: this._isRoundOnly,
      },
    };
  }

  private routeQueryParams(queryParam: Params): void {
    this._gameId = queryParam?.gameId;
    this._gameTitle = queryParam?.gameTitle;
    this._gameType = queryParam?.gameType;
    this._hasBonusRound = JSON.parse(queryParam?.hasBonusRound ?? null);
    this._roundId = queryParam.roundId;
    this._questionType = +queryParam.questionType;
    this._slideId = queryParam.slideId;
    this._isRoundOnly = JSON.parse(queryParam?.isRoundOnly ?? null);
  }

  private routeParams(params: Params): void {
    const typedString = params?.timeSamplingType as keyof typeof GameTimeSamplingType;
    this.timeSamplingType = GameTimeSamplingType[typedString];
    this._roundIndex = +params.roundIndex;
    this._slideIndex = +params.slideIndex;
  }

  private parserRoute(route: ActivatedRoute): void {
    this.routeQueryParams(route.snapshot.queryParams);
    this.routeParams(route.snapshot.params);
  }
}

export class Breadcrumb {
  constructor(public href: string, public title: string, public queryParams?: object) {}
}
