import { Component, Injector, OnDestroy, OnInit, Type } from '@angular/core';
import { appearanceAndHidingAnimation } from '@shared/animations/modal.animations';
import { ModalInterop } from '@interops/modal.interop';
import { WindowScrollingInterop } from '@interops/window.scrolling.interop';
import { Subscription } from 'rxjs';
import { BaseComponent } from '@page-modules/base/base.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [appearanceAndHidingAnimation],
})
export class ModalComponent extends BaseComponent implements OnInit, OnDestroy {
  public isVisible = false;
  public contentComponent: Type<any>;
  public injector: Injector;
  private _sub: Subscription;

  constructor(
    private _modalInterop: ModalInterop,
    private _windowScrollingInterop: WindowScrollingInterop,
    private _parentInjector: Injector
  ) {
    super();
  }

  ngOnInit(): void {
    this._sub = this._modalInterop.getObservable().subscribe((settings) => {
      if (settings) {
        this.contentComponent = settings.componentType;
        this._windowScrollingInterop.disable();
        this.isVisible = true;
        if (settings.arg) {
          const options = {
            providers: [
              {
                provide: settings.arg.type,
                useValue: settings.arg.value,
              },
              {
                provide: ActivatedRoute,
                useValue: settings.activatedRoute,
              },
            ],
            parent: this._parentInjector,
          };
          this.injector = Injector.create(options);
        } else {
          this.injector = this._parentInjector;
        }
      } else {
        if (this.isVisible) {
          this._windowScrollingInterop.enable();
          this.isVisible = false;
          this.contentComponent = null;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
