import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { LoaderInterop } from '@interops/loader.interop';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  public options: AnimationOptions;
  public heightPx: string;
  public widthPX: string;

  @Input() public isVisible = false;

  constructor(private _loaderInterop: LoaderInterop) {}

  private _height: number;

  @Input() set height(value: number) {
    this._height = value;
    this.heightPx = `${this._height}px`;
  }

  private _width: number;

  @Input() set width(value: number) {
    this._width = value;
    this.widthPX = `${this._width}px`;
  }

  public ngOnInit(): void {
    this.options = {
      path: '/assets/animation/loading_animation.json',
      autoplay: true,
      loop: true,
    };
  }
}
